@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body{
    font-family: 'Poppins';
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    height: auto;
}

.explr-btn{
    color: #0e1725;
    scale: 0.8;
    clip-path: polygon(0% 0%, 75% 0%, 85% 50%, 75% 100%, 0% 100%);
}

.explr-chevron{
    scale: 0.8;
    clip-path: polygon(50% 0%, 80% 50%, 50% 100%, 40% 100%, 70% 50%, 40% 0%);
}

.elabs-bg{
    background-color: #0e1725;
}

.factors{
    background-color: #0e1725;
}
/* 
.factors h3{
    font-family: "Beth Ellen", cursive;
} */

.projects{
    background-color: #0e1725;
}

.Elabs_innernav{
    background: linear-gradient(180deg, #0d1c30 0%, #0e1725 100%);
}

.info-stats{
    background-color: #0e1725;
    overflow: hidden;
}

.landingScreen {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 10vh 10vw;
    background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    overflow: hidden;
}
.landingScreen > .content-container {
    /* position: relative; */
    width: 100%;
    height: 100%;
}
.moveUp {
    position: relative;
    top: 0;
    font-size: 3vw;
    width: 100%;
    color: white;
    text-align: center;
    z-index: 10;
    text-transform: uppercase;
    line-height: 130%;
    letter-spacing: 5px;
    animation: moveUp 10s ease-in-out;
}
.landingScreen > .content-container > h2 {
    position: relative;
    background-size: 100% auto;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 13px;
    line-height: 130%;
    font-size: 7.5vw;
    text-align: center;
    color: white;
    text-transform: uppercase;
    z-index: 10;
    animation: fadeInUp 10s ease-in-out;
}
.landingScreen > .content-container > h3 {
    position: relative;
    font-family: "Teko", sans-serif;
    font-size: 3vw;
    margin-top: 10vh;
    color: white;
    text-align: center;
    z-index: 10;
    animation: fadeInUpp 10s ease-in-out;
}
@keyframes moveUp {
    0%, 15% {
        scale: 1.5;
        top: 40%;
    }
    30%, 100% {
        scale: 1;
        top: 0;
    }
}
@keyframes fadeInUp {
    0%, 15% {
        opacity: 0;
        top: 50%;
    }
    30%, 100% {
        opacity: 1;
        top: 10%;
    }
}
@keyframes fadeInUpp {
    0%, 25% {
        opacity: 0;
        top: 10%;
    }
    35%, 100% {
        opacity: 1;
        top: 0;
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: auto;
    z-index: 10;
}
.container .box {
    position: relative;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -120px 30px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}
.container .box::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    text-decoration: none;
    background: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: all 0.5s ease-in-out;
}

.container .box::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    background: #fff;
    border-radius: 20px;
    transform: skewX(15deg);
    transition: all 0.5s ease-in-out;
    filter: blur(30px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.container .box:hover:before,
.container .box:hover:after {
    transform: skewX(0deg);
    left: 50px;
    width: calc(100% - 90px);

}

.container .box:nth-child(1):before,
.container .box:nth-child(1):after {
    background-image: linear-gradient(to top, #990e3c, #b5194d, #c43464, #bf597b, #bf7a91, #d398ac, #ecc7d3, #bc686a, #cd8185, #dd9ba0, #edb5bb, #fcd0d6);
}

.container .box:nth-child(2):before,
.container .box:nth-child(2):after {
    background-image: linear-gradient(to top, #194e5e, #225b6b, #2b6779, #347587, #3d8295, #4190a3, #459fb1, #4aaebe, #4bc1cd, #4fd4db, #55e7e7, #5ffbf1);

}

.container .box .content {
    position: relative;
    left: 0;
    top: -4px;
    width: 100%;
    padding: 40px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    z-index: 1;
    color: #fff;
    animation: floating 3s ease-in-out infinite
}
.container .box .container:nth-child(2) {
    animation: floating 3s ease-in-out infinite;
}
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 8px); }
    100%   { transform: translate(0, -0px); }    
}
.container .box .content h4 {
    font-size: 2em;
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
}
.container .box .content p {
    font-size: .9em;
    margin-bottom: 10px;
    line-height: 1em;
    text-align: justify;
}
.slogansection {
    width: 100vw;
    min-height: 60vh;
    height: auto;
    padding: 15vh 15vw 45vh;
    background-color: white;
    --mask:
      radial-gradient(29.07vw at 50% calc(100% - 39vw),#000 99%,#0000 101%) calc(50% - 26vw) 0/52vw 100%,
      radial-gradient(29.07vw at 50% calc(100% + 26vw),#0000 99%,#000 101%) 50% calc(100% - 13vw)/52vw 100% repeat-x;
    -webkit-mask: var(--mask);
            mask: var(--mask);
    /* background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%); */
}

/* .............Star Animation start.................. */
.stars {
    position: relative;
    top: -440%;
    left: 10%;
    width: 150%;
    height: 500%;
    transform: rotate(-135deg);
}

.star {
    --star-color: rgba(78, 192, 233, 1);
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
}

@media screen and (max-width: 750px) {
    .star {
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }
}

.star:nth-child(1) {
    --star-tail-length: 5.85em;
    --top-offset: 56.55vh;
    --fall-duration: 9.528s;
    --fall-delay: 0.86s;
}

.star:nth-child(2) {
    --star-tail-length: 6.24em;
    --top-offset: 54.6vh;
    --fall-duration: 10.257s;
    --fall-delay: 5.783s;
}

.star:nth-child(3) {
    --star-tail-length: 5.06em;
    --top-offset: 13.71vh;
    --fall-duration: 6.409s;
    --fall-delay: 3.729s;
}

.star:nth-child(4) {
    --star-tail-length: 7.43em;
    --top-offset: 71.28vh;
    --fall-duration: 10.422s;
    --fall-delay: 0.079s;
}

.star:nth-child(5) {
    --star-tail-length: 7.02em;
    --top-offset: 90.5vh;
    --fall-duration: 6.031s;
    --fall-delay: 6.25s;
}

.star:nth-child(6) {
    --star-tail-length: 7.01em;
    --top-offset: 87.43vh;
    --fall-duration: 9.735s;
    --fall-delay: 4.401s;
}

.star:nth-child(7) {
    --star-tail-length: 6.06em;
    --top-offset: 24.52vh;
    --fall-duration: 9.567s;
    --fall-delay: 6.278s;
}

.star:nth-child(8) {
    --star-tail-length: 5.73em;
    --top-offset: 50.18vh;
    --fall-duration: 6.238s;
    --fall-delay: 2.229s;
}

.star:nth-child(9) {
    --star-tail-length: 6.8em;
    --top-offset: 93.64vh;
    --fall-duration: 10.191s;
    --fall-delay: 3.039s;
}

.star:nth-child(10) {
    --star-tail-length: 6.27em;
    --top-offset: 52.72vh;
    --fall-duration: 6.85s;
    --fall-delay: 6.369s;
}

.star:nth-child(11) {
    --star-tail-length: 6.04em;
    --top-offset: 42.69vh;
    --fall-duration: 6.474s;
    --fall-delay: 1.544s;
}

.star:nth-child(12) {
    --star-tail-length: 7.45em;
    --top-offset: 81.71vh;
    --fall-duration: 10.664s;
    --fall-delay: 5.319s;
}

.star:nth-child(13) {
    --star-tail-length: 7.08em;
    --top-offset: 63.97vh;
    --fall-duration: 9.821s;
    --fall-delay: 0.146s;
}

.star:nth-child(14) {
    --star-tail-length: 6.43em;
    --top-offset: 50.21vh;
    --fall-duration: 7.901s;
    --fall-delay: 6.377s;
}

.star:nth-child(15) {
    --star-tail-length: 5.18em;
    --top-offset: 40.91vh;
    --fall-duration: 6.39s;
    --fall-delay: 5.687s;
}

.star:nth-child(16) {
    --star-tail-length: 6.73em;
    --top-offset: 45.34vh;
    --fall-duration: 11.967s;
    --fall-delay: 7.527s;
}

.star:nth-child(17) {
    --star-tail-length: 5.02em;
    --top-offset: 43.57vh;
    --fall-duration: 11.403s;
    --fall-delay: 0.644s;
}

.star:nth-child(18) {
    --star-tail-length: 6.05em;
    --top-offset: 95.57vh;
    --fall-duration: 10.818s;
    --fall-delay: 9.377s;
}

.star:nth-child(19) {
    --star-tail-length: 7.48em;
    --top-offset: 76.5vh;
    --fall-duration: 11.863s;
    --fall-delay: 0.515s;
}

.star:nth-child(20) {
    --star-tail-length: 6.05em;
    --top-offset: 17.98vh;
    --fall-duration: 9.667s;
    --fall-delay: 7.981s;
}

.star:nth-child(21) {
    --star-tail-length: 6.35em;
    --top-offset: 35.41vh;
    --fall-duration: 9.303s;
    --fall-delay: 9.613s;
}

.star:nth-child(22) {
    --star-tail-length: 5.13em;
    --top-offset: 67.02vh;
    --fall-duration: 8.925s;
    --fall-delay: 0.319s;
}

.star:nth-child(23) {
    --star-tail-length: 5.02em;
    --top-offset: 47.81vh;
    --fall-duration: 7.986s;
    --fall-delay: 1.581s;
}

.star:nth-child(24) {
    --star-tail-length: 6.36em;
    --top-offset: 52.93vh;
    --fall-duration: 7.22s;
    --fall-delay: 4.078s;
}

.star:nth-child(25) {
    --star-tail-length: 5.29em;
    --top-offset: 49.48vh;
    --fall-duration: 8.441s;
    --fall-delay: 7.217s;
}

.star:nth-child(26) {
    --star-tail-length: 5.99em;
    --top-offset: 14.5vh;
    --fall-duration: 8.015s;
    --fall-delay: 4.922s;
}

.star:nth-child(27) {
    --star-tail-length: 6.71em;
    --top-offset: 59.15vh;
    --fall-duration: 6.064s;
    --fall-delay: 1.065s;
}

.star:nth-child(28) {
    --star-tail-length: 5.04em;
    --top-offset: 29.31vh;
    --fall-duration: 8.913s;
    --fall-delay: 6.804s;
}

.star:nth-child(29) {
    --star-tail-length: 7.46em;
    --top-offset: 9.74vh;
    --fall-duration: 9.304s;
    --fall-delay: 8.758s;
}

.star:nth-child(30) {
    --star-tail-length: 5.11em;
    --top-offset: 41.69vh;
    --fall-duration: 10.801s;
    --fall-delay: 4.475s;
}

.star:nth-child(31) {
    --star-tail-length: 5.25em;
    --top-offset: 23.83vh;
    --fall-duration: 9.783s;
    --fall-delay: 1.542s;
}

.star:nth-child(32) {
    --star-tail-length: 7.03em;
    --top-offset: 67.85vh;
    --fall-duration: 9.85s;
    --fall-delay: 4.788s;
}

.star:nth-child(33) {
    --star-tail-length: 6.22em;
    --top-offset: 2.7vh;
    --fall-duration: 7.782s;
    --fall-delay: 1.549s;
}

.star:nth-child(34) {
    --star-tail-length: 6.95em;
    --top-offset: 11.33vh;
    --fall-duration: 10.177s;
    --fall-delay: 3.134s;
}

.star:nth-child(35) {
    --star-tail-length: 7.16em;
    --top-offset: 18.6vh;
    --fall-duration: 10.033s;
    --fall-delay: 2.84s;
}

.star:nth-child(36) {
    --star-tail-length: 6.96em;
    --top-offset: 31.94vh;
    --fall-duration: 11.253s;
    --fall-delay: 8.114s;
}

.star:nth-child(37) {
    --star-tail-length: 5.23em;
    --top-offset: 11.68vh;
    --fall-duration: 7.635s;
    --fall-delay: 0.313s;
}

.star:nth-child(38) {
    --star-tail-length: 6.26em;
    --top-offset: 98.74vh;
    --fall-duration: 9.545s;
    --fall-delay: 6.08s;
}

.star:nth-child(39) {
    --star-tail-length: 6.39em;
    --top-offset: 8.48vh;
    --fall-duration: 10.62s;
    --fall-delay: 8.856s;
}

.star:nth-child(40) {
    --star-tail-length: 6.77em;
    --top-offset: 14.38vh;
    --fall-duration: 8.182s;
    --fall-delay: 1.729s;
}

.star:nth-child(41) {
    --star-tail-length: 6.27em;
    --top-offset: 43.89vh;
    --fall-duration: 7.093s;
    --fall-delay: 8.584s;
}

.star:nth-child(42) {
    --star-tail-length: 5.17em;
    --top-offset: 33.28vh;
    --fall-duration: 9.469s;
    --fall-delay: 5.373s;
}

.star:nth-child(43) {
    --star-tail-length: 6.31em;
    --top-offset: 80.04vh;
    --fall-duration: 8.186s;
    --fall-delay: 7.808s;
}

.star:nth-child(44) {
    --star-tail-length: 6.2em;
    --top-offset: 23.66vh;
    --fall-duration: 8.764s;
    --fall-delay: 3.466s;
}

.star:nth-child(45) {
    --star-tail-length: 7.38em;
    --top-offset: 49.58vh;
    --fall-duration: 10.154s;
    --fall-delay: 0.537s;
}

.star:nth-child(46) {
    --star-tail-length: 6.88em;
    --top-offset: 50.44vh;
    --fall-duration: 11.619s;
    --fall-delay: 8.521s;
}

.star:nth-child(47) {
    --star-tail-length: 7.08em;
    --top-offset: 53.79vh;
    --fall-duration: 6.143s;
    --fall-delay: 0.494s;
}

.star:nth-child(48) {
    --star-tail-length: 6.05em;
    --top-offset: 22.45vh;
    --fall-duration: 11.996s;
    --fall-delay: 5.728s;
}

.star:nth-child(49) {
    --star-tail-length: 6.6em;
    --top-offset: 27.82vh;
    --fall-duration: 9.864s;
    --fall-delay: 4.134s;
}

.star:nth-child(50) {
    --star-tail-length: 6.78em;
    --top-offset: 28.49vh;
    --fall-duration: 6.554s;
    --fall-delay: 7.548s;
}

.star::before,
.star::after {
    position: absolute;
    content: "";
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
}

.star::before {
    transform: rotate(45deg);
}

.star::after {
    transform: rotate(-45deg);
}

@keyframes fall {
    to {
        transform: translate3d(-30em, 0, 0);
    }
}

@keyframes tail-fade {

    0%,
    50% {
        width: var(--star-tail-length);
        opacity: 1;
    }

    70%,
    80% {
        width: 0;
        opacity: 0.4;
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

@keyframes blink {
    50% {
        opacity: 0.6;
    }
}

  /* Demo container css */
  
.demo-cont{
    padding: 15vh 10vw 5vh;
    background: #012045;
    background: linear-gradient(180deg, #0e1725 0%, #010101 100%);
}
  .demo{
    font-family: 'Poppins';
    font-size: 14px;
    color: white;
  }

  .demo-cont h3{
    font-family: "Teko", sans-serif;
    font-size: 3em;
    font-weight: 600;
    color: white;
  }
 .book-form-cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin-top: 10px;
    padding: 20px 20px;
    gap: 40px;
 }
 .quote-cont{
    background: linear-gradient(to right bottom, rgb(16 43 83 / 40%), rgba(255, 255, 255, 0.2));
    backdrop-filter: blur(1rem);
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
    color: black;
    border-radius: 12px;
    padding: 40px;
    color: white;
    font-size: 14px;
    justify-content: space-between;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);  
 }
 .upper-line{
    width: 100%;
    height: auto;
 }
 .upper-line p{
    font-weight: bold;
    letter-spacing: .03em;
    font-family: 'Poppins';
    font-size: 18px;
 }
.upper-line hr {
    width: 100%;
    height: 1px;
    border: none;
    margin: 1em 0;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.4));
}
 .namespan{
    display: block;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Poppins';
 }
 .positionspan{
    display: block;
    padding-top: .5em;
    font-size: 12px;
    font-family: 'Poppins';
    color: whitesmoke;
 }

 .demo-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    background-color: white;
    width: 45%;
    height: 100%;
    gap: 20px;
    border-radius: 12px;
    padding: 20px 20px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
 }

 .demo-form label{
    font-family: 'Poppins';
    color: #194E5E;
    font-size: 12px;
 }
 .demo-form input,
 .demo-form textarea {
    width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid rgb(171, 171, 171);
    padding: 9px 18px;
    font-size: 12px;
    font-family: 'Poppins';
    outline: none;
 }
 .demo-form input:focus,
 .demo-form input:active,
 .demo-form textarea:focus,
 .demo-form textarea:active {
    outline: none;
    border: 1px solid #004a9e;
 }
 .name-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 }
 .fname, .lname{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 48%;
 }
 .email-cont{
    width: 100%;
 }
 .project-cont{
    width: 100%;
 }
 .project-cont span{
    font-family: 'Poppins';
    color: #194E5E;
    font-size: 12px;
 }
 .projectss{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 }
 .stan-pro, .dash-pro, .expert-pro{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
 }
 .stan-pro > input, .dash-pro > input , .expert-pro > input {
    display: inline;
    width: min-content;
    margin: 0 5px;
 }
 .stan-pro > label, .dash-pro > label, .expert-pro > label {
    font-size: 14px;
 }
 .question-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
 }

 .question-cont textarea{
    height: 80px;
 }

 .date-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
 }

 .date-cont input{
    width: 50%;
}

 .demo-form button{
    width: 100%;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 4px;
    color: white;
    font-family: "Poppins";
    padding: 10px 10px;
    cursor: pointer;
    background: linear-gradient(to right bottom, rgb(16, 43, 83 ), rgb(40, 68, 123));
    transition: all .5s ease-in-out;
 }

 .demo-form button:hover{
    background: linear-gradient(to right bottom, rgb(16, 43, 83 ), rgb(12, 22, 40));
 }
 
.join-us {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 50vh;
    background-color: rgb(237, 237, 237);
  }
  
  .expert-title {
    color: #800;
    font-family: 'Poppins';
    font-weight: bolder;
    font-size: 50px;
  }
  
  .expert-text {
    font-family: 'Poppins';
    font-size: 18px;
    width: 50%;
    text-align: center;
  }
  
  .join-btn {
    background-color: white;
    color: black;
    font-family: 'Poppins';
    padding: 14px 28px;
    border: none;
    border-radius: 50px;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
  }
  
  .join-btn:hover {
    background-color: whitesmoke;
  }

  @media screen and (min-width: 320px) and (max-width: 640px) {
    .demo-cont{
        width: 100%;
        padding: 2em;
    }

    .quote-cont{
        display: none;
    }

    .demo-form{
        width: 100%;
        background-color: transparent;
        padding: 0;
    }

    .book-form-cont{
        padding: 0;
    }

    .demo-form label{
        color: white;
    }

    .project-cont span{
        color: white;
    }

    .demo-form input, .demo-form textarea{
        padding: 5px 10px;
        background-color: transparent;
        color: white;
    }

    .stan-pro > label, .dash-pro > label, .expert-pro > label{
        font-size: 10px;
        color: white;
    }

    .date-cont input{
        font-size: 10px;
    }

    .demo-form button{
        font-size: 10px;
        padding: 5px 10px;
    }
  }