@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.sub-text {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 0.8rem;
    width: 100%;
}

.footer-sec{
    background-color: #1e1e1e;
}