.ip-card:hover > .ip-card-txt > .ip-card-link{
    display: block;
}

.ip-card:hover{
    cursor: pointer;
}

.ip-card{
    border-radius: 4px;
    border: none;
    box-shadow: 4px 4px 0.8 black;
}

.ip-card:hover{
    filter: blur(0);
    background: rgb(0,50,170);
    background: linear-gradient(315deg, rgba(0,50,170,1) 0%, rgba(0,24,56,1) 100%);
    transition: all 1s ease-in;
}

.ip-card:hover > img{
    filter: brightness(0) invert(1);
    transition: all 0.4s ease-in;
}