.c-form{
    box-shadow: 0px 0px 2px black;
}

.inp-shadow{
    box-shadow: 0px 0px 2px black;
    padding: 5px;
} 
.shade{
    box-shadow: 0px 0px 2px grey;
    background-color: whitesmoke;
}
.shade2{
    box-shadow: 0px 0px 2px grey;
    
}
.shade1{
    box-shadow: 0px 0px 2px grey;
}
.fil{
    filter: brightness(60%);
    transition: all 0.2s ease-out;
}
.overlay:hover > .fil{
    filter: brightness(80%);
    scale: 1.2;
}
.overlay:hover > .line-parent > .line{
    width: 90%;
}
.line{
    border-top: 2px solid red;
    width: 0%;
    transition: width 0.2s ease-out;
}
input::placeholder{
    font-size: 12px;
}
.social{
    z-index: 99;
    bottom: -2.8em;
    left: 4em;
}
.insights{
    color: white;
    font-size: 3em;
    font-weight: bold;
}
.background{
    box-shadow: 0px 0px 2px whitesmoke;
   
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .c-form{
        box-shadow: none;
    }
}