.head-img-C{
    display: block;
    max-width: 100%;
    margin: auto;
    height: auto;
    max-height: 65vh;
    object-fit: cover;
}
.head-img-R {
    float: right;
    height: auto;
    margin: 5px 20px;
}
.head-img-L {
    float: left;
    height: auto;
    margin: 5px 20px;
}

p b{
    font-weight: 500;
}

/* .keytakeaways p ul li{
    list-style-type: none;
    background-image: url('../assets/bullets.png'); 
    background-repeat: no-repeat;
    background-position: 0 0; 
    padding-left: 1.5rem; 
    background-size: 0.7rem; 
} */

.hyperlink{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    padding-top: 20px;
    width: 75%;
}

.hyperlink a{
    color: blue;
    text-decoration: underline;
}

p a{
    color: blue;
    text-decoration: underline;
}

p li{
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
}

p ol{
    list-style-type: decimal;
     /* list-style-position: inside; */
     margin-left: 20px;
    font-family: "Roboto", sans-serif;
}

p ul{
    /* list-style-position: inside; */
    margin-left: 20px;
    font-family: "Roboto", sans-serif;
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

p b{
    font-weight: 500;
}

.hyperlink li{
    /* font-customFont2 font-light text-[16px] */
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 5px;
}


.hyperlink p{
    /* font-customFont2 font-light text-[16px] */
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
}

.hyperlink h3{
    font-family: "GFS Didot", serif;
    font-weight: normal;
    font-size: 1.5rem;
}

/* .cardcont{
    width: 37% !important;
} */  


@media screen and (min-width: 320px) and (max-width: 640px) {

    .slick-prev{
        left: 0 !important;
    }

    .slick-next{
        right: 0 !important;
    }

    .slidecard{
        width: 100% !important;
        height: 40vh !important;
    }

    .slidecard-txt{
        top: 10px;
        left: 10px;
    }
    
    .slidecard-tag{
        font-size: 8px;
    }
    
    .slidecard h3{
        font-size: 14px;
        font-weight: 600;
    } 
    
    .slidecard p{
        font-size: 10px;
    }    
}

.report-analytics h3{
    font-size: 1.75rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #464646;
}