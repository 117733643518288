.sep-card{
    display: flex;
    flex-direction: column;
    align-items: start;
}

/* .sep-card:hover > .sep-card-txt .sep-card-head{
    width: 100%;
    font-size: 1.2rem !important;
    transition: all 0.5s ease-in;
} */

.sep-card:hover > .sep-card-txt .sep-card-body{
    opacity: 100%;
    transition: all 0.5s ease-in;
}

.sep-card:hover > .sep-card-txt .sep-card-bait{
    display: none;
    transition: all 0.5s ease-in;
}

.sep-card:hover > img{
    scale: 500;
    transition: all 5s ease-in;
}