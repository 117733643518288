.subsec:hover > img{
    transform: scale(1.2);
    /* transform: scale(150%) rotate(360deg); */
}

.subsec > img{
    transition: all .2s ease-out;
}

.active-inner{
    color: #800;
    font-weight: 600;
}

.active-tab{
    color: #800;
    font-weight: 600;
}

.tab-heads button{
    box-shadow: 0px 0px 1px black; 
    padding: 1.25rem;
    border-radius: 0.25rem;
    font-size: 12px;
}

.tab-heads-resp a{
    box-shadow: 0px 0px 1px black; 
    padding: 0.8rem;
    border-radius: 0.25rem;
    font-size: 12px;
    text-align: center;
}

.case-card{
    position: relative;
    overflow: hidden;
    height: 20rem;
}

.casecard-title{
    font-size: 1.5rem;
    font-weight: 600;
}

.case-card:hover > .case-overlay{
    opacity: 30%;
}

.testimonies .slick-prev{
    left: -10rem !important;
}

.testimonies .slick-next{
    right: -10rem !important;
}

.ind-banner img{
    scale: 1;
    transition: transform 0.3s ease-out;
    transition-delay: .3s;
}

.ind-banner:hover > img{
    transform: scale(1.1); 
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .case-card{
        height: 10rem;
        width: 100%;
    }

    .casecard-title{
        font-size: 1em;
        height: 4.5em;
        overflow: hidden;
    }

    .testimonies .slick-next, .testimonies .slick-prev{
        display: none !important;
    }

    .subsec:hover > img{
        transform: scale(1);
    }
}