.bg-element{
    position: absolute;
    top: 35vh;
    left: 0;
    height: 200vh;
    width: 100vw;
    /* background: rgb(93,224,230); */
    background: linear-gradient(90deg, rgba(93,224,230,1) 0%, rgba(0,74,173,1) 100%);
    clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
    z-index: 2;
}

.vmHeading{
    color: #003561;
}

.banner-cta{
    color: #41a5e5;
}

.labsVM{
    position: sticky;
    top: 90px;
}

.in-nav-active a:hover{
    color: #800;
    font-weight: 600;
}