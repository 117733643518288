.whobg{
    position: relative;
    background: url(../assets/Rectangle\ 190.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    width: 80%;
    height: 100%;
}

.fade-over-right{
    min-height: 100%;
    min-width: 50%;
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 1) 0%, hsla(0, 0%, 100%, 1) 58%, hsla(0, 0%, 100%, 0) 100%);
}
.fade-over-left{
    min-height: 100%;
    min-width: 50%;
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 1) 40%, hsla(0, 0%, 100%, 1) 100%);
}

.about-people-imgs{
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    box-shadow: 0px 0px 1px black;
}

.gd-about{
    background-size: 150%;
    background-position: 30% 0;
    background-image: url(../assets/people-imgs/GD-bgremoved.png);
}
.md-about{
    background-size: 120%;
    background-position: 40% 30%;
    background-image: url(../assets/people-imgs/101\ -\ Manasi\ Dhawan.jpg);
}
.vp-about{
    background-size: 120%;
    background-position: 40% 30%;
    background-image: url(../assets/people-imgs/Vinod.png);
}

@media screen and (min-width: 320px) and (max-width: 640px) {
    .about-people-imgs{
        width: 50px;
        height: 50px;
    }
}