@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Montserrat";
}

.banner1 img {
    animation: scale 9s infinite ease-in-out;
    animation-delay: .3s;
}

.banner2 img {
    animation: scale 9s infinite ease-in-out;
    animation-delay: 8800ms;
}

.banner3 img {
    animation: scale 9s infinite ease-in-out;
    animation-delay: 17600ms;
}

@keyframes scale {
    from{
        scale: 1;
    }
    to{
        scale: 1.2;
    }
}

.banner{
    position: relative;
    overflow: hidden;
    /* height: 68vh; */
}

.content-container {
    position: absolute;
    top: 90px;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 98%;
    gap: 9px;
    /* padding: 33px 40px  33px 87px */
}

.main-heading-text {
    width: 100%;
    height: auto;

}

.slick-dots{
    bottom: 2em !important;
    right: 2em;
    width: fit-content !important;
    z-index: 99;
}

.slick-dots li{
    width: 55px;
    height: 10px;
}

.slick-dots li button{
    width: 55px;
    height: 10px;
}

.slick-dots li button::before{
    background-color: #fff;
    color: transparent !important;
    border-radius: 50px;
    width: 55px;
    height: 10px;
}

.banner .slick-slider .slick-next, .banner .slick-slider .slick-prev{
    display: none !important;
}

@media screen and (min-width: 320px) and (max-width:640px) {
    .slick-dots li{
        width: 20px;
        height: 10px;
    }
    
    .slick-dots li button{
        width: 20px;
        height: 10px;
    }
    .slick-dots li button::before{
        background-color: #fff;
        color: transparent !important;
        border-radius: 50px;
        width: 20px ;
        height: 5px;
    }
}

::-webkit-scrollbar-thumb{
    background-color: #B00d0e;
    width: 2px;
}

::-webkit-scrollbar-track{
    width: 2px;
    background-color: #efefef97;
    border-radius: 5px;
}