/* *{
    color: #464646;
} */

.head-img-C{
    display: block;
    max-width: 100%;
    margin: auto;
    height: auto;
    max-height: 65vh;
    object-fit: cover;
}
.head-img-R {
    float: right;
    height: auto;
    margin: 5px 20px;
    width: 38%;
}
.head-img-L {
    float: left;
    height: auto;
    margin: 5px 20px;
    width: 38%;
}

/* .keytakeaways p ul li{
    list-style-type: none;
    background-image: url('../assets/bullets.png'); 
    background-repeat: no-repeat;
    background-position: 0 0; 
    padding-left: 1.5rem; 
    background-size: 0.7rem; 
} */

.hyperlink{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
    padding-top: 20px;
    width: 75%;
}

.hyperlink a{
    color: blue;
    text-decoration: underline;
}

p a{
    color: blue;
    text-decoration: underline;
}

p li{
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
}

p ol{
     /* list-style-position: inside; */
     list-style-type: decimal;
     margin-left: 20px;
    font-family: "Roboto", sans-serif;
}

p ul{
    /* list-style-position: inside; */
    margin-left: 20px;
    font-family: "Roboto", sans-serif;
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

p b{
    font-weight: 500;
}

.hyperlink li{
    /* font-customFont2 font-light text-[16px] */
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 5px;
}


.hyperlink h3{
    font-family: "GFS Didot", serif;
    font-weight: normal;
    font-size: 1.5rem;
}

/* .cardcont{
    width: 37% !important;
} */

.slick-prev, .slick-next {
    width: 50px !important;
    height: 50px !important;
    padding: 4px !important;
    border-radius: 100%;
    border: 1px solid #1e1e1e !important;
    background: rgba(255, 255, 255, 0.354) !important;
    z-index: 49;
}

.slick-prev:before, .slick-next:before {
    font-family: 'Montserrat' !important;
    font-size: 33px !important;
    line-height: .5 !important;
    color: #1e1e1e !important;
}

.slidecard{
    position: relative;
    width: 100% !important;
    height: 80vh !important;
    scale: 0.8;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #cacaca;
}

a:hover > .slidecard{
    transition: scale 0.5s ease-out;
    scale: 0.9;
}

.slidecard-txt{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    color: #464646;
    padding: 1rem;
}

.slidecard-tag{
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    background-color: white;
    color: #1e1e1e;
}

.slidecard-tag:hover{
    background-color: white;
    color: #1e1e1e !important;
}

.slidecard h3{
    bottom: 30%;
    font-size: 20px;
    font-weight: 600;
} 

.slidecard p{
    bottom: 0;
    font-size: 14px;
}

.slidecard img{
    object-fit: cover;
    height: 50%;
    width: 100%;
    filter: brightness(50%);
    transition: all 0.2s ease-out;
}

.slick-center{
    transition: scale 0.5s ease-out;
    scale: 1.1;
}

.objectContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 27px 16px;
    border-top: 1px solid rgb(210, 120, 120);
    border-bottom: 1px solid rgb(210, 120, 120);
}

.object{
    width: 50%;
    background-color: whitesmoke;
    padding: 10px 20px;
}

.object h3{
    font-size: 18px;
    font-weight: bold;
}


.object li{
    list-style-type: disc;
}

.keyquestions{
    width: 50%;
    padding: 10px 20px;
    background-color: rgb(49, 49, 49);
    color: white;
}

.keyquestions h3{
    font-size: 18px;
    font-weight: bold;
}

.keyquestions li{
    list-style-type: disc;
}


@media screen and (min-width: 320px) and (max-width: 640px) {

    .slick-prev{
        left: 0 !important;
    }

    .slick-next{
        right: 0 !important;
    }

    .slidecard{
        width: 100% !important;
        height: 40vh !important;
    }

    .slidecard-txt{
        top: 10px;
        left: 10px;
    }
    
    .slidecard-tag{
        font-size: 8px;
    }
    
    .slidecard h3{
        font-size: 14px;
        font-weight: 600;
    } 
    
    .slidecard p{
        font-size: 10px;
    }    
}