.active-sustainability{
    background-color: #800;
    color: white;
}

.test-bg{
    position: absolute;
    display: flex;
    height: 66%;
    width: 70%;
    top: 5rem;
    left: 12rem;
    clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 10%, 95% 10%, 95% 50%, 50% 50%, 45% 100%, 100% 100%, 100% 0%);
}

.active-sus-driver{
    background-color: #22543d !important;
}

.active-sus-driver-txt{
    /* color: #22543d; */
    color: #1c6575;
}