.red-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 138vh;
    background-color: #800;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 80%);
    z-index: 2;
}


.people-cards:hover{
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: all 0.5s ease-out;
    /* cursor: pointer; */
}

.people-cards:hover > .people-img .people-circle{
    transform: scale(0.9);
    border: 1px solid #012072;
    transition: all 0.5s ease-out;
}


@media screen and (min-width: 320px) and (max-width: 640px) {
    .people-circle{
        border: 2px solid #800;
    }
    .people-cards{
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: all 0.5s ease-out;
        cursor: pointer;
    }
}