.fil-label{
    background-color: whitesmoke;
    color: #7a7a7a;
    font-weight: 600;
    padding: 0.5rem;
    font-size: 10px;
    border-radius: 50px;
    margin: 5px 0;
    cursor: pointer;
}

.chk-filter:checked ~ .fil-label{
    background-color: rgb(153 27 27);
    color: white;
}

.cardcontainer{
    box-shadow: 0px 0px 4px #cacaca;
}

.card-img{
    clip-path: circle(49.1% at 0 100%);
    transition: all 1s ease-out;
}

.cardcontainer:hover > .card-img{
    clip-path: circle(112.4% at 27% 76%);
    transition: all 1s ease-out;
    opacity: 30%;
}

.cardcontainer:hover > .art-card .cardLink{
    font-weight: 600;
}