@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.industry-bar, .solution-bar{
    z-index: 999;
    position: absolute;
    top: 55px;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 61vh;
    background-color: #000000b8;
    left: 0px;
}

.subIndustries{
    width: 33.3%;
    height: 100%;
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subIndustries li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.sub-info{
    width: 33.3%;
    height: 100%;
    background-color: rgba(30, 59, 138, 0.575);
}

.sub-info p {
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 1.2rem;
}

.arts-section{
    width: 33.3%;
    height: 100%;
}

